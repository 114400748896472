import { ChangeEvent } from 'react';
import { useFormContext } from 'react-hook-form';
import { DEFAULT_LOCALE } from '../variables';

/**
 * Костыль для обновления имени в поле `name` при изменении `locales[0].name`.
 *
 * Они должны отправляться одинаковыми, иначе запрос вернёт ошибку.
 */
export const useUpdateName = () => {
  const { setValue, getValues } = useFormContext();

  const handleChangeName = (e: ChangeEvent<HTMLInputElement>) => {
    const locales = getValues('locales');
    const defaultLocaleName = getValues('name');
    const defaultLocaleIndex = locales.findIndex(({ locale }) => locale === DEFAULT_LOCALE);

    if (e.target.name === `locales[${defaultLocaleIndex}].name`) {
      const newValue = e.target.value;

      locales.forEach(({ locale, name }, index) => {
        if (locale !== DEFAULT_LOCALE && (!name || name === defaultLocaleName)) {
          setValue(`locales[${index}].name`, newValue, { shouldDirty: true });
        }
      });

      setValue('name', newValue, { shouldDirty: true });
    }
  };

  return handleChangeName;
};
