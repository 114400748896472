import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import {
  Children,
  FC,
  PropsWithChildren,
  SyntheticEvent,
  cloneElement,
  isValidElement,
  useEffect,
  useState,
} from 'react';
import { Locale } from '../variables';
import { TextInput } from 'ra-ui-materialui';
import { capitalize } from '../utils/string';
import { useFieldArray, useFormContext } from 'react-hook-form';

type Props = PropsWithChildren<{
  /**
   * @deprecated
   * позже будет удалён, останется `locale`
   */
  langSource?: 'lang' | 'locale';
}>;

/**
 * Аналог одноимённого компонента из библиотеки react-admin, но работает со следующим типом данных:
 *
 * @example
 * type Languages = {
 *   lang: Locale; // lang - изменяемый параметр langSource
 *   [key in string]: any;
 * }
 */
export const TranslatableInput: FC<Props> = (props) => {
  const { langSource = 'lang', children } = props;
  const { control, getFieldState } = useFormContext();
  const source = 'locales';
  const locales = Object.values(Locale);
  const errors = getFieldState(source).error;

  const { replace } = useFieldArray({ control, name: source });

  useEffect(() => {
    const orderedLocales = locales.map((locale) => ({ [langSource]: locale }));
    replace(orderedLocales);
  }, [replace, langSource, locales]);

  const [value, setValue] = useState(0);

  const handleChange = (event: SyntheticEvent, _value: number) => {
    setValue(_value);
  };

  const mapTabLabels = () => {
    return locales.map((locale, index) => {
      const hasError = Children.toArray(children).some((child) => {
        if (!isValidElement(child)) {
          return false;
        }
        return Object.keys(errors?.[index] || {}).includes(child.props.source);
      });

      return <Tab style={{ color: hasError ? 'red' : '' }} label={locale} value={index} key={locale} />;
    });
  };

  const mapTabContent = () => {
    return locales.map((locale, index) => {
      const currentLocaleSource = `${source}[${index}]`;

      return (
        <Box display={value === index ? 'flex' : 'none'} flexDirection="column" key={source + locale}>
          <TextInput
            style={{ visibility: 'hidden', height: 0, width: 0 }}
            source={`${currentLocaleSource}.${langSource}`}
            defaultValue={locale}
          />
          {Children.map(children, (child) => {
            if (!isValidElement(child)) {
              return null;
            }

            if (typeof child.type !== 'function') {
              return child;
            }

            const fieldSource = `${currentLocaleSource}.${child.props.source}`;

            return cloneElement(child, {
              ...child.props,
              source: fieldSource,
              label: child.props.label ?? capitalize(child.props.source),
              inputProps: {
                ...child.props?.inputProps,
                lang: locale,
              },
            });
          })}
        </Box>
      );
    });
  };

  return (
    <Box sx={{ border: 1, borderColor: 'divider', borderRadius: 2, padding: 1, mb: 2 }} minWidth="50%">
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange}>
          {mapTabLabels()}
        </Tabs>
      </Box>

      {mapTabContent()}
    </Box>
  );
};
